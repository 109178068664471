// import { useAppDispatch } from "hooks"
// import { setLoading } from "store/actions/app";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "constants/config";
import axios from "./axios";

const API: any = async ({
  url,
  method,
  responseType,
  data,
  cancelToken,
  // baseUrlType,
  params,
  headers,
}) => {
  //REQUEST JSON
  // const dispatch = useAppDispatch();

  let axiosRequestObject = {
    method,
    url,
    // baseURL: getBaseUrl(baseUrlType),
    data,
    headers,
    responseType,
    params,
    ...(cancelToken
      ? {
          cancelToken,
        }
      : ""),
  };

  //REQUEST

  let request = await axios
    .request(axiosRequestObject)
    .then(handleSuccessRequest)
    .catch((err) => handleErrorRequest(err, axiosRequestObject));

  return request;
};

const handleSuccessRequest = (response) => ({
  status: response.status,
  data: response.data,
});

const handleErrorRequest = (err, axiosRequestObject) => {
  if (!err.response) {
    return Promise.reject();
  } else if (err.response?.status === 401) {
    const originalRequest = err.config;
    if (!originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem(REFRESH_TOKEN);
      if (refreshToken) {
        let axiosRequestRefreshTokenObject = {
          ...axiosRequestObject,
          method: "POST",
          url: "/auth/refresh-token",
          data: { refresh_token: refreshToken },
        };

        return axios
          .request(axiosRequestRefreshTokenObject)
          .then(async (res: any) => {
            const token = res?.data?.access_token;
            const refreshToken = res?.data?.refresh_token;
            localStorage.setItem(ACCESS_TOKEN, token);
            localStorage.setItem(REFRESH_TOKEN, refreshToken);
            originalRequest.headers.Authorization = `Bearer ${token}`;

            return axios
              .request(originalRequest)
              .then(handleSuccessRequest)
              .catch((err) => {
                window.location.href = "/login";
              });
          })
          .catch((_error) => {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(REFRESH_TOKEN);

            window.location.href = "/login";
          });
      } else return Promise.reject(err.response);
    }
  } else return Promise.reject(err.response);
};

export default API;
