import { combineReducers } from "redux";
import alert from "./alert";
import app from "./app";
import auth from "./auth";
import comment from "./comment";
import dataAnalytics from "./dataAnalytics";
import datamap from "./datamap";
import dataSummary from "./dataSummarys";
import project from "./project";
import user from "./user";
import dataGeotechnical from "./dataGeotechnical";
import dataGeneralised from "./generalisedSoil";
import geologicalFormation from "./geologicalFormation";
import listFilterQuery from "./filterQuery";
import dataWeathering from "./dataWeathering";
import dataMajorFraction from "./dataMajorFraction";
import dataSubordinate from "./dataSubordinate";
import graphDAA from "./graphDAA";
import dataMapDigga2 from "./dataMapDIGGA2";

export default combineReducers({
  auth,
  app,
  user,
  alert,
  project,
  datamap,
  comment,
  dataSummary,
  dataAnalytics,
  dataGeotechnical,
  dataGeneralised,
  geologicalFormation,
  listFilterQuery,
  dataWeathering,
  dataMajorFraction,
  dataSubordinate,
  graphDAA,
  dataMapDigga2,
});
