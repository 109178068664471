import { dataAnalyticsActionType } from "../actionTypes";

const initialState = {
  isLoadDataAnalytics: false,
  dataAnalytics: [],
  listDataSecondLevel: [],
  listDataGraphCalculation: [],
  listDataFirstLevel: [],
  listDataValueInputTrace: [],
};

const dataAnalytics = (state = initialState, action) => {
  switch (action.type) {
    case dataAnalyticsActionType.IS_LOAD_DATA_ANALYTICS_SUCCESS:
      return {
        ...state,
        isLoadDataAnalytics: action.payload,
      };
    case dataAnalyticsActionType.SET_LIST_DATA_ANALYTICS:
      return {
        ...state,
        dataAnalytics: action.payload,
      };
    case dataAnalyticsActionType.SET_LIST_DATA_SECOND_LEVEL:
      return {
        ...state,
        listDataSecondLevel: action.payload,
      };
    case dataAnalyticsActionType.SET_LIST_GRAPH_CALCULATION_INPUT:
      return {
        ...state,
        listDataGraphCalculation: action.payload.listDataGraphCalculation,
        listDataFirstLevel: action.payload.listDataFirstLevel,
        listDataValueInputTrace: action.payload.listDataValueInputTrace,
      };
    default:
      return state;
  }
};

export default dataAnalytics;
