import { ACCESS_TOKEN } from "constants/config";
import { lazy } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import PATHS from "./const";
import { useAppSelector } from "hooks";

const Project = lazy(() => import("pages/Project"));
const ProjectList = lazy(() => import("pages/ProjectList"));
const ProjectsHistory = lazy(() => import("pages/ProjectHistory"));
const MyAccount = lazy(() => import("pages/MyAccount"));
const Login = lazy(() => import("pages/Auth/Login"));

type routeItem = {
  path: string;
  title: string;
  key: string;
  exact: boolean;
  component: Function;
  requiredAuth: boolean;
  name: string;
};

type routes = routeItem & {
  routes?: routeItem[];
};

const ROUTES: routes[] = [
  {
    path: PATHS.HOMEPAGE,
    title: "DIGGA | Projects List",
    key: "ROOT",
    exact: true,
    component: ProjectList,
    routes: [],
    requiredAuth: true,
    name: "list-projects",
  },
  {
    path: PATHS.NEW_PROJECT,
    title: "DIGGA | New Project",
    key: "ROOT",
    exact: true,
    component: Project,
    routes: [],
    requiredAuth: true,
    name: "new-project",
  },
  {
    path: PATHS.PROJECT,
    title: "DIGGA | Project Detail",
    key: "ROOT",
    exact: true,
    component: Project,
    routes: [],
    requiredAuth: true,
    name: "project-detail",
  },
  {
    path: PATHS.LIST_PROJECTS,
    title: "DIGGA | Projects List",
    key: "ROOT",
    exact: true,
    component: ProjectList,
    routes: [],
    requiredAuth: true,
    name: "list-projects",
  },
  {
    path: PATHS.LIST_PROJECTS_HISTORY,
    title: "DIGGA | Projects History",
    key: "ROOT",
    exact: true,
    component: ProjectsHistory,
    routes: [],
    requiredAuth: true,
    name: "list-projects-history",
  },
  {
    path: PATHS.EDIT_PROJECT,
    title: "DIGGA | Edit project",
    key: "ROOT",
    exact: true,
    component: Project,
    routes: [],
    requiredAuth: true,
    name: "edit-project",
  },
  {
    path: PATHS.RAW_DATA_DETAIL_PAGE,
    title: "DIGGA | Raw data detail page",
    key: "ROOT",
    exact: true,
    component: Project,
    routes: [],
    requiredAuth: true,
    name: "raw-data",
  },
  {
    path: PATHS.GEOTECHNICAL_DETAIL_PAGE,
    title: "DIGGA | Geotechnical detail page",
    key: "ROOT",
    exact: true,
    component: Project,
    routes: [],
    requiredAuth: true,
    name: "geotechnical-detail",
  },
  {
    path: PATHS.DATA_PARENT_ANALYTICS_DETAIL,
    title: "DIGGA | Data parent analytics detail page",
    key: "ROOT",
    exact: true,
    component: Project,
    routes: [],
    requiredAuth: true,
    name: "data_analytics",
  },
  {
    path: PATHS.DATA_CHILD_ANALYTICS_DETAIL,
    title: "DIGGA | Data child analytics detail page",
    key: "ROOT",
    exact: true,
    component: Project,
    routes: [],
    requiredAuth: true,
    name: "data_child_analytics",
  },
  {
    path: PATHS.DATA_CHILD_ANALYTICS_DETAIL_PDF,
    title: "DIGGA | Data child analytics detail page",
    key: "ROOT",
    exact: true,
    component: Project,
    routes: [],
    requiredAuth: true,
    name: "data_child_analytics_pdf",
  },
  {
    path: PATHS.EXPORT_TO_PDF,
    title: "DIGGA | Export PDF",
    key: "ROOT",
    exact: true,
    component: Project,
    routes: [],
    requiredAuth: false,
    name: "export_pdfs",
  },
  {
    path: PATHS.EXPORT_TO_EXCEL,
    title: "DIGGA | Export Excel",
    key: "ROOT",
    exact: true,
    component: Project,
    routes: [],
    requiredAuth: false,
    name: "export_excel",
  },
  {
    path: PATHS.ACCOUNT,
    title: "DIGGA | My account",
    key: "ROOT",
    exact: true,
    component: MyAccount,
    routes: [],
    requiredAuth: true,
    name: "myaccount",
  },
  {
    path: PATHS.LOGIN,
    title: "DIGGA | Login",
    key: "ROOT",
    exact: true,
    component: Login,
    routes: [],
    requiredAuth: false,
    name: "login",
  },
];

export default ROUTES;

export function RenderRoutes({ routes }: { routes: routes[] }) {
  const [projectNumberTitle] = useAppSelector((state) => [
    state.project.projectNumberTitle,
  ]);

  return (
    <Switch>
      {routes.map((route, i) => {
        if (route.path === PATHS.EDIT_PROJECT) {
          route.title = `DIGGA | ${projectNumberTitle}`;
        }
        return <RouteWithSubRoutes {...route} />;
      })}

      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  );
}

function RouteWithSubRoutes(route: routes) {
  const history = useHistory();
  document.title = route.title;

  return (
    <Route
      key={route.key}
      path={route.path}
      exact={route.exact}
      render={(props) => {
        localStorage.setItem("currentRoute", route.name);
        if (!localStorage.getItem(ACCESS_TOKEN)) {
          route.requiredAuth && history.push("/login");
        } else {
          ["/login", "/register", "/forgot-password"].includes(route.path) &&
            history.push("/");
        }
        return <route.component {...props} routes={route.routes} />;
      }}
    />
  );
}
