import { appActionType } from "../actionTypes";


const initialState = {
    show: false,
    message: "",
    type: "success"
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case appActionType.SET_ALERT:
            return {
                ...state,
                show: true,
                message: action.message,
                type: action.alertType || "success"
            }
        case appActionType.REMOVE_ALERT:
            return {
                ...state,
                show: false,
                message: ""
            }
        default:
            return state;
    }
}

export default app