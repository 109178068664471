import { dataWeatheringActionType } from "store/actionTypes";

const initialState = {
  listWeathering: [],
};

const dataWeathering = (state = initialState, action) => {
  switch (action.type) {
    case dataWeatheringActionType.SET_LIST_DATA_WEATHERING:
      return { listWeathering: action.payload };
    default:
      return state;
  }
};

export default dataWeathering;
