export const authActionType = {
  LOGIN: "LOGIN",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  SEND_EMAIL_FORGOT_PASSWORD_SUCCESS: "SEND_EMAIL_FORGOT_PASSWORD_SUCCESS",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  REMEMBER_ME: "REMEMBER_ME",
  SET_FORGOT_PASSWORD_DATA: "SET_FORGOT_PASSWORD_DATA",
  CHANGE_PASSWORD_FORGOT_PASSWORD_SUCCESS:
    "CHANGE_PASSWORD_FORGOT_PASSWORD_SUCCESS",
  LOGOUT: "LOGOUT",
  ERROR_LOGIN: "ERROR_LOGIN",
  ERROR_SEND_EMAIL_FORGOT_PASSWORD: "ERROR_SEND_EMAIL_FORGOT_PASSWORD",
  ERROR_CHANGE_PASSWORD_FORGOT_PASSWORD:
    "ERROR_CHANGE_PASSWORD_FORGOT_PASSWORD",
  CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",
  FIRST_TIME_RESET_PASSWORD_SUCCESS: "FIRST_TIME_RESET_PASSWORD_SUCCESS",
  ERROR_FIRST_TIME_RESET_PASSWORD: "ERROR_FIRST_TIME_RESET_PASSWORD",
  SET_FIRST_TIME_RESET_PASSWORD_DATA: "SET_FIRST_TIME_RESET_PASSWORD_DATA",
  GET_BY_ID: "GET_BY_ID",
  GET_MY_ACCOUNT: "GET_MY_ACCOUNT",
  ERROR_MY_ACCOUNT: "ERROR_MY_ACCOUNT",
  UPDATE_MY_ACCOUNT_SUCCESS: "UPDATE_MY_ACCOUNT_SUCCESS",
  CHANGE_PASSWORD_MY_ACCOUNT_SUCCESS: "CHANGE_PASSWORD_MY_ACCOUNT_SUCCESS",
  ERROR_CHANGE_PASSWORD_MY_ACCOUNT: "ERROR_CHANGE_PASSWORD_MY_ACCOUNT",
};

export const appActionType = {
  SET_LOADING: "SET_LOADING",
  SET_ALERT: "SET_ALERT",
  REMOVE_ALERT: "REMOVE_ALERT",
};

export const userActionType = {
  GET_LIST: "GET_LIST",
  GET_BY_ID: "GET_BY_ID",
  ADD_USER: "ADD_USER",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ERROR_ADD_USER: "ERROR_ADD_USER",
};

export const projectActionType = {
  GET_TABS: "GET_TABS",
  GET_LIST: "GET_LIST",
  SET_SELECTION_POINTS: "SET_SELECTION_POINTS",
  GET_BY_ID: "GET_BY_ID",
  GET_PROJECT: "GET_PROJECT",
  ADD_PROJECT: "ADD_PROJECT",
  ADD_PROJECT_SUCCESS: "ADD_PROJECT_SUCCESS",
  ERROR_ADD_PROJECT: "ERROR_ADD_PROJECT",
  SET_COUNT_AUTO_SAVE_PROJECT: "SET_COUNT_AUTO_SAVE_PROJECT",
  SET_PROJECT_NUMBER: "SET_PROJECT_NUMBER",
};

export const mapViewerActionType = {
  GET_LIST_POINS: "GET_LIST_POINS",
  GET_SELECTION_POINTS: "GET_SELECTION_POINTS",
  ERROR_MESSAGE: "ERROR_MESSAGE",
  QUERY_DATA_FILTER_MAP: "QUERY_DATA_FILTER_MAP",
  SET_UNLINE_AND_OPACITY_DATA_SUMMARY: "SET_UNLINE_AND_OPACITY_DATA_SUMMARY",
  CLEAR_MULTIPLE_POINTS: "CLEAR_MULTIPLE_POINTS",
  IS_LOAD_MAP: "IS_LOAD_MAP",
  UPDATE_BOUND_MAP: "UPDATE_BOUND_MAP",
  UPDATE_MAP_FILTERED: "UPDATE_MAP_FILTERED",
  UPDATE_DATA_POINT_FILTER: "UPDATE_DATA_POINT_FILTER",
  UPDATE_DATA_MAPVIEWERS: "UPDATE_DATA_MAPVIEWERS",
};

export const dataMapViewAction = {
  UPDATE_LIST_POINS: "UPDATE_LIST_POINS",
  IS_LOAD_MAP: "IS_LOAD_MAP",
};

export const commentActionType = {
  IS_LOAD_COMMENT_SUCCESS: "IS_LOAD_COMMENT_SUCCESS",
  REGISTER_COMMENT: "COMMENT_REGISTER",
  GET_COMMENTS_PROJECT: "GET_COMMENTS_PROJECT",
  ERROR_COMMENT: "ERROR_COMMENT",
};

export const dataSummaryActionType = {
  IS_LOAD_DATA_SUMMARY_SUCCESS: "IS_LOAD_DATA_SUMMARY_SUCCESS",
  SET_LIST_DATA_SUMMARY: "SET_LIST_DATA_SUMMARY",
  SET_ID_DATA_SUMMARY: "SET_ID_DATA_SUMMARY",
  SET_LIST_DATA_SUMMARY_DETAIL: "SET_LIST_DATA_SUMMARY_DETAIL",
};

export const dataAnalyticsActionType = {
  IS_LOAD_DATA_ANALYTICS_SUCCESS: "IS_LOAD_DATA_ANALYTICS_SUCCESS",
  SET_LIST_DATA_ANALYTICS: "SET_LIST_DATA_ANALYTICS",
  SET_LIST_DATA_SECOND_LEVEL: "SET_LIST_DATA_SECOND_LEVEL",
  SET_LIST_GRAPH_CALCULATION_INPUT: "SET_LIST_GRAPH_CALCULATION_INPUT",
};

export const dataGeotechnicalActionType = {
  IS_LOAD_DATA_GEOTECHNICAL_SUCCESS: "IS_LOAD_DATA_GEOTECHNICAL_SUCCESS",
  SET_LIST_DATA_GEOTECHNICAL: "SET_LIST_DATA_GEOTECHNICAL",
  SET_ID_DATA_GEOTECHNICAL: "SET_ID_DATA_GEOTECHNICAL",
  SET_DATA_GEOTECHNICAL: "SET_DATA_GEOTECHNICAL",
  SET_LIST_ASIGNEE_DATA_GEOTECHNICAL: "SET_LIST_ASIGNEE_DATA_GEOTECHNICAL",
};

export const dataGeneralisedSoil = {
  SET_LIST_DATA_GENERALISED_SOIL: "SET_LIST_DATA_GENERALISED_SOIL",
};

export const geologicalFormationType = {
  SET_LIST_DATA_GEOLOGICAL_FORMATION: "SET_LIST_DATA_GEOLOGICAL_FORMATION",
};

export const filterQuery = {
  SET_LIST_FILTER_QUERY: "SET_LIST_FILTER_QUERY",
  SET_LIST_FILTER_QUERY_MAP: "SET_LIST_FILTER_QUERY_MAP",
};

export const dataWeatheringActionType = {
  SET_LIST_DATA_WEATHERING: "SET_LIST_DATA_WEATHERING",
};

export const dataMajorFractionActionType = {
  SET_LIST_DATA_MAJOR_FRACTION: "SET_LIST_DATA_MAJOR_FRACTION",
};

export const dataSubordinateActionType = {
  SET_LIST_DATA_SUBORDINATE: "SET_LIST_DATA_SUBORDINATE",
};

export const graphDAAType = {
  SET_LIST_GRAPH_PARAMETER: "SET_LIST_GRAPH_PARAMETER",
  SET_LIST_GRAPH_CALCULCATION: "SET_LIST_GRAPH_CALCULATION",
  SET_COLOR_TRACE: "SET_COLOR_TRACE",
  SET_MODE_TRACE: "SET_MODE_TRACE",
  SET_IS_CHANGE_TYPE: "SET_IS_CHANGE_TYPE",
  SET_IS_CALL_API_PLOT: "SET_IS_CALL_API_PLOT",
};

export const dataMapDIGGA2 = {
  SET_ALL_DATA_MAP: "SET_ALL_DATA_MAP",
  SET_DATA_SUMMARY: "SET_DATA_SUMMARY",
  SET_DATA_SELECTED_INVESTIGATION: "SET_DATA_SELECTED_INVESTIGATION",
  SET_DATA_BOUND: "SET_DATA_BOUND",
};
