import { dataSubordinateActionType } from "store/actionTypes";

const initialState = {
  listSubordinate: [],
};

const dataSubordinate = (state = initialState, action) => {
  switch (action.type) {
    case dataSubordinateActionType.SET_LIST_DATA_SUBORDINATE:
      return { listSubordinate: action.payload };
    default:
      return state;
  }
};

export default dataSubordinate;
