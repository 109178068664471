import { filterQuery } from "store/actionTypes";

const initialState = {
  listDataFilterAllPage: [],
  listTypeOptionAllPage: [],
  listDataFilterMap: [],
  listTypeOptionMap: [],
};

const listFilterQuery = (state = initialState, action) => {
  switch (action.type) {
    case filterQuery.SET_LIST_FILTER_QUERY:
      return {
        ...state,
        listDataFilterAllPage: action.payload.listDataFilterAllPage,
        listTypeOptionAllPage: action.payload.listTypeOptionAllPage,
      };
    case filterQuery.SET_LIST_FILTER_QUERY_MAP:
      return {
        ...state,
        listDataFilterMap: action.payload.listDataFilterMap,
        listTypeOptionMap: action.payload.listTypeOptionMap,
      };
    default:
      return state;
  }
};

export default listFilterQuery;
