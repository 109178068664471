import { authActionType } from "../actionTypes";

const initialState = {
  isLoginSuccess: false,
  myAccount: {},
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case authActionType.LOGIN_SUCCESS:
      return {
        ...state,
        isLoginSuccess: action.payload,
      };
    case authActionType.LOGOUT:
      return {
        ...state,
        isLoginSuccess: false,
      };
    case authActionType.GET_MY_ACCOUNT:
      return {
        ...state,
        myAccount: action.payload,
      };
    default:
      return state;
  }
};

export default auth;
