import { dataMapDIGGA2 } from "../actionTypes";
const initialState = {
  dataSummary: [],
  dataSelected: [],
  bounds: undefined,
  selectedInvestigations: "",
};
const dataMapDigga2 = (state = initialState, action) => {
  switch (action.type) {
    case dataMapDIGGA2.SET_ALL_DATA_MAP:
      return {
        ...state,
        dataSummary: action.payload.dataSummary,
        dataSelected: action.payload.dataSelected,
        selectedInvestigations: action.payload.selectedInvestigations,
        bounds: action.payload.bounds || state.bounds,
      };
    case dataMapDIGGA2.SET_DATA_SUMMARY:
      return {
        ...state,
        dataSummary: action.payload.dataSummary,
        dataSelected: action.payload.dataSelected,
        selectedInvestigations: action.payload.selectedInvestigations,
      };
    case dataMapDIGGA2.SET_DATA_BOUND:
      return {
        ...state,
        bounds: action.payload,
      };
    default:
      return state;
  }
};
export default dataMapDigga2;
